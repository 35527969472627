import { checkEditing } from "./checkEditing";
import { updateCell } from "./updateCell";
import { keyArrowUpDown } from "./keyArrowUpDown";
import { getTagList } from "./getTagList";

export const surveiKeyEvent = (
    setIsAddTagModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setTags: React.Dispatch<React.SetStateAction<never[]>>,
    table_rows: {
        tags: {
            id: number;
            c_code: string;
            name: string;
        }[];
        id: number;
        goal: string;
        code: string;
    }[]
): void => {
    const active_id = "main_table_active";
    document.body.addEventListener("keydown", (e) => {
        // 取得したデータがない場合は処理しない
        if (!table_rows.length || !table_rows[0].id) return;

        // 現在のactiveの場所を取得
        const main_table_active = document.getElementById(
            active_id
        ) as HTMLElement;

        const tr = main_table_active.parentNode as HTMLElement;

        // セルを編集中かを確認
        const is_editing = checkEditing(main_table_active);

        switch (e.key) {
            case "ArrowRight": // → を押したら
                if (main_table_active.dataset.column === "code") break;

                if (is_editing) break;

                main_table_active.removeAttribute("id");

                const next_elem = main_table_active.nextElementSibling as HTMLElement;
                next_elem.id = active_id;

                break;
            case "ArrowLeft": // ← を押したら
                if (is_editing) break;

                const prev_elem = main_table_active.previousElementSibling;
                if (prev_elem) {
                    // 前のtdが存在したら処理
                    main_table_active.removeAttribute("id");
                    prev_elem.id = active_id;
                }
                break;
            case "ArrowDown": // ↓ を押したら
                if (is_editing) break;
                const next_tr = tr.nextElementSibling as HTMLElement;
                keyArrowUpDown(main_table_active, active_id, next_tr);
                break;
            case "ArrowUp": // ↑ を押したら
                if (is_editing) break;
                const prev_tr = tr.previousElementSibling as HTMLElement;
                keyArrowUpDown(main_table_active, active_id, prev_tr);
                break;
            case "F2":
                if (main_table_active.dataset.column === "tag") {
                    // タグ一覧を取得
                    getTagList(setTags);

                    // モーダル表示
                    setIsAddTagModalShow(true);
                } else if (is_editing) {
                    main_table_active.setAttribute("contentEditable", "false");
                    main_table_active.classList.remove("editing");
                    // 更新処理
                    updateCell(main_table_active);
                } else {
                    main_table_active.setAttribute("contentEditable", "true");
                    main_table_active.classList.add("editing");
                    main_table_active.focus();
                }
                break;
            case "Tab":
                e.preventDefault();
                document.execCommand("insertHTML", false, "&#009");
                break;
        }
    });
};
