interface Title {
    name: string;
}

class Title {
    constructor() {
        this.name = "Program Library";
    }
}

export default Title;
