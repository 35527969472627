import React, { useContext } from "react";
import { PagerProps, RouterProps } from "../main";
import { Pagination } from "react-bootstrap";
import onClickPageBtn from "../../../functions/onClickPageBtn";
import extractUrlParams from "../../../functions/extractUrlParams";

export const Up = (): any => {
    const [pager] = useContext(PagerProps);

    const router = useContext(RouterProps);

    const { s } = extractUrlParams(router.location.search);

    const current_num = pager.current_num;
    const last_num = pager.last_num;

    if (current_num === 1) {
        return (
            <React.Fragment>
                <Pagination.Item active={true}>1</Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 2)}>
                    2
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 3)}>
                    3
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, pager.last_num)}
                >
                    {pager.last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (current_num === 2) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Item active={true}>2</Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 3)}>
                    3
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, pager.last_num)}
                >
                    {pager.last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (current_num === 3) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 2)}>
                    2
                </Pagination.Item>
                <Pagination.Item active={true}>3</Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 4)}>
                    4
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, pager.last_num)}
                >
                    {pager.last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (current_num === 4) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 2)}>
                    2
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 3)}>
                    3
                </Pagination.Item>
                <Pagination.Item active={true}>4</Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, 5)}>
                    5
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, pager.last_num)}
                >
                    {pager.last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (last_num === current_num + 3) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 1)}
                >
                    {current_num - 1}
                </Pagination.Item>
                <Pagination.Item active={true}>{current_num}</Pagination.Item>
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num + 1)}
                >
                    {current_num + 1}
                </Pagination.Item>
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num + 2)}
                >
                    {current_num + 2}
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, last_num)}>
                    {last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (last_num === current_num + 2) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 1)}
                >
                    {current_num - 1}
                </Pagination.Item>
                <Pagination.Item active={true}>{current_num}</Pagination.Item>
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num + 1)}
                >
                    {current_num + 1}
                </Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, last_num)}>
                    {last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (last_num === current_num + 1) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 1)}
                >
                    {current_num - 1}
                </Pagination.Item>
                <Pagination.Item active={true}>{current_num}</Pagination.Item>
                <Pagination.Item onClick={() => onClickPageBtn(s, last_num)}>
                    {last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    } else if (last_num === current_num) {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 2)}
                >
                    {current_num - 2}
                </Pagination.Item>
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 1)}
                >
                    {current_num - 1}
                </Pagination.Item>
                <Pagination.Item active={true}>{last_num}</Pagination.Item>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <Pagination.Item onClick={() => onClickPageBtn(s, 1)}>
                    1
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num - 1)}
                >
                    {current_num - 1}
                </Pagination.Item>
                <Pagination.Item active={true}>{current_num}</Pagination.Item>
                <Pagination.Item
                    onClick={() => onClickPageBtn(s, current_num + 1)}
                >
                    {current_num + 1}
                </Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item onClick={() => onClickPageBtn(s, last_num)}>
                    {last_num}
                </Pagination.Item>
            </React.Fragment>
        );
    }
};
