import React, { useContext } from "react";
import { Pagination } from "react-bootstrap";
import { PagerSwitch } from "./pagerSwitch";
import onClickPageBtn from "../../../functions/onClickPageBtn";
import { RouterProps, PagerProps } from "../main";
import extractUrlParams from "../../../functions/extractUrlParams";
import { onClickPrevBtn } from "../../../functions/onClickPrevBtn";
import { onClickNextBtn } from "../../../functions/onClickNextBtn";

export const PagerMain = () => {
    const router = useContext(RouterProps);
    const [pager] = useContext(PagerProps);

    const { s } = extractUrlParams(router.location.search);

    return (
        <Pagination className=" justify-content-center">
            <Pagination.First onClick={() => onClickPageBtn(s, 1)} />
            <Pagination.Prev
                onClick={() => onClickPrevBtn(pager.current_num, s)}
            />
            <PagerSwitch />
            <Pagination.Next onClick={() => onClickNextBtn(s, pager)} />
            <Pagination.Last
                onClick={() => onClickPageBtn(s, pager.last_num)}
            />
        </Pagination>
    );
};
