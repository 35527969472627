import React, { useContext } from "react";
import { PagerProps, RouterProps } from "../main";
import { generatePagerElems } from "../../../functions/generatePagerElems";

export const Under = (): any => {
    const [pager] = useContext(PagerProps);

    const router = useContext(RouterProps);

    // pagerの要素配列を作成
    const pager_list = generatePagerElems(pager, router);

    return <React.Fragment>{pager_list}</React.Fragment>;
};
