export default function extractUrlParams(search: string) {
    if (search === "" || search === "?") {
        const s = "";
        const p = 1;
        return { s, p };
    }
    // ?削除
    const remove_question = search.slice(1);

    // =で分割
    const splited = remove_question.split("=");

    let s = "";
    let p = 0;
    splited.forEach((v, i) => {
        const sp = v.split("&");
        if (i === 1) {
            s = sp[0];
        }
        if (i === 2) {
            p = Number(sp[0]);
        }
    });
    return { s, p };
}
