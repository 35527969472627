export const onClickTag = async (
    e: any,
    setIsUpdateTagModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectTag: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
            button: HTMLButtonElement;
            color: string;
        }>
    >
) => {
    const button = e.target as HTMLButtonElement;

    // モーダル表示
    setIsUpdateTagModalShow(true);

    // タグ情報のセット
    setSelectTag({
        id: Number(button.dataset.id),
        name: button.innerText,
        button: button,
        color: String(button.dataset.color),
    });
};
