import { toBool } from "../functions/toBool";

interface Host {
    name: string;
}

class Host {
    constructor() {
        const is_pro = toBool(process.env.REACT_APP_IS_PRO as string);
        if (!is_pro) {
            this.name = "http://local.api.arisya.net";
        } else {
            this.name = "https://api.arisya.net";
        }
    }
}

export default Host;
