import Axios from "axios";
import swal from "sweetalert";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const deleteTag = async (
    tag_id: number,
    button: HTMLButtonElement,
    setIsUpdateTagModalShow: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const params = new URLSearchParams();

    const tr = button.parentNode?.parentNode as HTMLElement;

    params.append("detail_id", String(tr.dataset.id));
    params.append("tag_id", String(tag_id));

    try {
        Axios({
            url: `${host_ins.name}/tags/`,
            method: "DELETE",
            data: params,
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        // button要素削除
        button.remove();
    } catch (err) {
        swal({
            title: err.response.statusText,
            icon: "error",
            text: err.response.data.err_mes,
        });
    } finally {
        setIsUpdateTagModalShow(false);
    }
};
