// import useHistory from "react-router-dom";
import extractCookieData from "../functions/extractCookieData";
import CookieKey from "../configs/cookieKey";
import { isUndefined } from "util";
import Path from "../configs/path";
import swal from "sweetalert";

const path_ins = new Path();
const cookieKey_ins = new CookieKey();

export default async function onClickLogout(e: any) {
    async function deleteToken() {
        document.cookie = `${cookieKey_ins.token}=; max-age=0`;
    }

    e.preventDefault();

    // token削除
    await deleteToken();

    if (isUndefined(extractCookieData(cookieKey_ins.token))) {
        // tokenが消えてたらLogin画面へ
        window.location.href = path_ins.login;
    } else {
        swal({
            title: "ログアウトできない(^^)/",
            icon: "error",
        });
    }
}
