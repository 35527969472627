import onClickPageBtn from "./onClickPageBtn";

export const onClickNextBtn = (
    s: string,
    pager: {
        current_num: number;
        last_num: number;
    }
): void => {
    if (pager.current_num === pager.last_num) {
        return;
    }

    onClickPageBtn(s, pager.current_num + 1);
};
