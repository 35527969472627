import React, { useEffect, useContext, useState } from "react";
import { Table, Button } from "react-bootstrap";
import "highlight.js/scss/tomorrow-night.scss";
import getDisplayData from "../../functions/getDisplayData";
import { PagerProps, RouterProps } from "./main";
import onClickDelete from "../../functions/onClickDelete";
import { surveiKeyEvent } from "../../functions/surveiKeyEvent";
import extractUrlParams from "../../functions/extractUrlParams";
import setSearchInput from "../../functions/setSearchInput";
import { onClickTag } from "../../functions/onClickTag";
import { ModalAdd } from "./modal/add";
import { ModalUpdate } from "./modal/update";

export const MainTable = () => {
    const [, setPager] = useContext(PagerProps);
    const router = useContext(RouterProps);
    const [is_add_tag_modal_show, setIsAddTagModalShow] = useState(false);
    const [is_update_tag_modal_show, setIsUpdateTagModalShow] = useState(false);
    const [select_tag, setSelectTag] = useState({});
    const [tags, setTags] = useState([]);
    const [table_rows, setTableRow] = useState([
        {
            tags: [
                {
                    id: 0,
                    c_code: "",
                    name: "",
                },
            ],
            id: 0,
            goal: "",
            code: "",
        },
    ]);

    useEffect(() => {
        // searchからパラメータを抽出
        const { s, p } = extractUrlParams(router.location.search);

        // searchにセット
        setSearchInput(s);

        // メインテーブルを表示する
        getDisplayData(setTableRow, setPager, s, p);
    }, [setTableRow, setPager, router.location.search]);

    useEffect(() => {
        // キーイベントを監視
        surveiKeyEvent(setIsAddTagModalShow, setTags, table_rows);
    }, [table_rows]);

    return (
        <React.Fragment>
            <Table id="main_table">
                <thead>
                    <tr>
                        <th>タグ</th>
                        <th>概要</th>
                        <th>詳細</th>
                        <th>削除</th>
                    </tr>
                </thead>
                <tbody>
                    {table_rows.map((row, i) => (
                        <tr key={i} data-id={row.id}>
                            <td className="tag" data-column="tag">
                                {row.tags.map((tag, j) => (
                                    <button
                                        key={j}
                                        style={{ background: `${tag.c_code}` }}
                                        onClick={(e: any) =>
                                            onClickTag(
                                                e,
                                                setIsUpdateTagModalShow,
                                                setSelectTag
                                            )
                                        }
                                        data-id={tag.id}
                                        data-color={tag.c_code}
                                    >
                                        {tag.name}
                                    </button>
                                ))}
                            </td>
                            <td
                                data-column="goal"
                                className="pre"
                                spellCheck="false"
                            >
                                {row.goal}
                            </td>
                            <td data-column="code" spellCheck="false">
                                <pre>
                                    <code>{row.code}</code>
                                </pre>
                            </td>
                            <td data-column="delete">
                                <Button
                                    variant="outline-danger"
                                    className="outline-accent-btn"
                                    onClick={(e: any) => {
                                        onClickDelete(e, row.id);
                                    }}
                                >
                                    <i className="fas fa-times"></i>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* タグの追加 */}
            <ModalAdd
                setIsAddTagModalShow={setIsAddTagModalShow}
                is_add_tag_modal_show={is_add_tag_modal_show}
                tags={tags}
            />

            {/* タグの削除と色の変更 */}
            <ModalUpdate
                setIsUpdateTagModalShow={setIsUpdateTagModalShow}
                is_update_tag_modal_show={is_update_tag_modal_show}
                select_tag={select_tag}
                url_params={router.location.search}
            />
        </React.Fragment>
    );
};
