import Axios from "axios";
import { insertTag } from "./insertTag";
import swal from "sweetalert";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const registNewTag = async (
    setIsAddTagModalShow: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const tag_form = document.getElementById("tag_form") as HTMLFormElement;

    // 現在のactiveの場所を取得
    const main_table_active = document.getElementById(
        "main_table_active"
    ) as HTMLElement;

    const id = main_table_active.parentElement?.dataset.id;
    const color = tag_form.tag_color.value;
    const name = tag_form.tag_name.value;

    const params = new URLSearchParams();
    params.append("id", String(id));
    params.append("color", color);
    params.append("name", name);

    try {
        await Axios({
            url: `${host_ins.name}/tags/`,
            method: "POST",
            data: params,
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        // テーブルにタグを入れる
        insertTag(color, name, main_table_active);
    } catch (err) {
        swal({
            title: err.response.statusText,
            icon: "error",
            text: err.response.data.err_mes,
        });
    } finally {
        // モーダル閉じる
        setIsAddTagModalShow(false);
    }
};
