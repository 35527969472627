import React, { useEffect } from "react";
import { Container, Form, Jumbotron, Button } from "react-bootstrap";
import onClickLogin from "../functions/onClickLogin";
import { errAlert } from "../functions/errAlert";
import Title from "../configs/title";

const title_ins = new Title();

export const Login = () => {
    useEffect(() => {
        // エラー表示
        errAlert();

        // タイトル設定
        document.title = title_ins.name;
    });

    return (
        <Container className="container-min pt-5">
            <Jumbotron>
                <h1 className="text-center">{title_ins.name}</h1>
            </Jumbotron>
            <Form id="login_form">
                <Form.Group controlId="email">
                    <Form.Label>メールアドレス</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>パスワード</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter password"
                    />
                </Form.Group>
                <Button
                    block
                    variant="outline-success"
                    className="outline-main-btn"
                    onClick={(e: any) => onClickLogin(e)}
                >
                    ログイン
                </Button>
            </Form>
        </Container>
    );
};
