import { registNewTag } from "./registNewTag";
import { selectExistTag } from "./selectExistTag";

export const onClickAddTag = (
    setModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    select_tag_num: number
) => {
    // 分岐
    switch (select_tag_num) {
        case 1:
            // 既存から
            selectExistTag(setModalShow);
            break;
        case 2:
            // 新しく登録
            registNewTag(setModalShow);
            break;
    }
};
