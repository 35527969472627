import { isUndefined } from "util";

export const addColorEditCell = (): void => {
    const first_td = document.querySelectorAll("#main_table tr")[1];
    if (isUndefined(first_td)) {
        // 空っぽだったら終了
        return;
    }

    const td = first_td.children[0];

    // idセット
    td.id = "main_table_active";
};
