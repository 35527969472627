import React from "react";
import { Pagination } from "react-bootstrap";
import onClickPageBtn from "./onClickPageBtn";
import extractUrlParams from "./extractUrlParams";

export const generatePagerElems = (
    pager: {
        current_num: number;
        last_num: number;
    },
    router: {
        history: {};
        location: {
            search: string;
        };
    }
) => {
    const { s } = extractUrlParams(router.location.search);

    const pager_list = [];

    for (let i = 1; i <= pager.last_num; i++) {
        if (i === pager.current_num) {
            pager_list.push(
                <Pagination.Item key={i} active={true}>
                    {i}
                </Pagination.Item>
            );
            continue;
        }
        pager_list.push(
            <Pagination.Item key={i} onClick={() => onClickPageBtn(s, i)}>
                {i}
            </Pagination.Item>
        );
    }

    return pager_list;
};
