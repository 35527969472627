import Axios from "axios";
import swal from "sweetalert";
import extractUrlParams from "./extractUrlParams";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const onClickChangeColor = async (
    tag_id: number,
    setIsUpdateTagModalShow: React.Dispatch<React.SetStateAction<boolean>>,
    url_params: string
) => {
    const tag_form = document.getElementById("tag_form") as HTMLFormElement;

    const params = new URLSearchParams();

    params.append("tag_id", String(tag_id));
    params.append("color", tag_form.tag_color.value);

    const { s, p } = extractUrlParams(url_params);

    try {
        await Axios({
            url: `${host_ins.name}/tags/`,
            method: "PATCH",
            data: params,
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        window.location.href = `/?s=${s}&p=${p}`;
    } catch (err) {
        swal({
            title: err.response.statusText,
            icon: "error",
            text: err.response.data.err_mes,
        });
    } finally {
        // モーダル閉じる
        setIsUpdateTagModalShow(false);
    }
};
