import asyncLogin from "./asyncLogin";

export default async function executeLogin() {
    const login_form = document.getElementById("login_form") as HTMLFormElement;

    // email取得
    const email = login_form.email.value;

    // password取得
    const password = login_form.password.value;

    // paramsに詰め込んで
    const params = new URLSearchParams();
    params.append("email", email);
    params.append("password", password);

    // ログイン
    return await asyncLogin(params);
}
