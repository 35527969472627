import { isUndefined } from "util";

export const keyArrowUpDown = (
    main_table_active: HTMLElement,
    active_id: string,
    next_tr: HTMLElement
) => {
    const tds = next_tr?.children;

    if (isUndefined(tds)) return;

    // 要素の配列を作成
    const tds_list = Array.from(tds);

    // 次のtdに枠を付ける
    for (const td of tds_list) {
        const td_e = td as HTMLElement;
        if (td_e.dataset.column === main_table_active.dataset.column) {
            td_e.id = active_id;
            break;
        }
    }

    // 前の枠は削除
    main_table_active.removeAttribute("id");
};
