import swal from "sweetalert";
import Axios from "axios";
import deleteTR from "./deleteTR";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export default async function onClickDelete(e: any, id: number) {
    const target = e.target;

    // アラート表示
    const swal_ans = await swal({
        title: "Warning",
        text: "この行を削除する？",
        icon: "warning",
        buttons: ["キャンセル", "削除"],
        dangerMode: true,
    });

    if (swal_ans) {
        // trueなら削除する
        const params = new URLSearchParams();
        params.append("id", String(id));

        try {
            await Axios({
                url: `${host_ins.name}/mainTables/`,
                method: "DELETE",
                data: params,
                headers: {
                    Authorization: `Bearer ${extractCookieData(
                        cookieKey_ins.token
                    )}`,
                },
            });

            // TR要素を削除
            deleteTR(target);
        } catch (err) {
            swal({
                icon: "error",
                title: err.response.statusText,
                text: err.response.data.err_mes,
            });
        }
    }
}
