import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";
import { isUndefined } from "util";
import swal from "sweetalert";

const cookie_key_ins = new CookieKey();

export const errAlert = () => {
    if (!isUndefined(extractCookieData(cookie_key_ins.login_err))) {
        swal({
            icon: "error",
            title: "ログインして",
        });
    }

    // token削除
    document.cookie = `${cookie_key_ins.login_err}=; max-age=0`;
};
