import swal from "sweetalert";
import Axios from "axios";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const getTagList = async (
    setTags: React.Dispatch<React.SetStateAction<never[]>>
) => {
    try {
        const res = await Axios({
            url: `${host_ins.name}/tags/`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        // セット
        setTags(res.data.tags);
    } catch (err) {
        swal({
            title: err.response.statusText,
            icon: "error",
            text: err.response.data.err_mes,
        });
    }
};
