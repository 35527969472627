import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { onChangeSelectTag } from "../../../functions/onChangeSelectTag";
import { onClickDeleteTag } from "../../../functions/onClickDeleteTag";
import { onClickChangeColor } from "../../../functions/onClickChangeColor";

export const ModalUpdate = (props: any) => {
    const setIsUpdateTagModalShow: React.Dispatch<React.SetStateAction<
        boolean
    >> = props.setIsUpdateTagModalShow;
    const is_update_tag_modal_show = props.is_update_tag_modal_show;
    const select_tag: {
        id: number;
        name: string;
        button: HTMLButtonElement;
        color: string;
    } = props.select_tag;
    const url_params: string = props.url_params;
    const [select_tag_num, setSelectTagNum] = useState(1);

    return (
        <Modal
            show={is_update_tag_modal_show}
            onHide={() => setIsUpdateTagModalShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    「{select_tag.name}」{select_tag_num === 1 && "を削除"}
                    {select_tag_num === 2 && "の色を変更"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="tag_form">
                    <fieldset>
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="削除"
                                name="select_update_tag"
                                id="select_delete"
                                checked={select_tag_num === 1}
                                onChange={() =>
                                    onChangeSelectTag(1, setSelectTagNum)
                                }
                            />
                            <Form.Check
                                type="radio"
                                label="色の変更"
                                name="select_update_tag"
                                id="select_color"
                                checked={select_tag_num === 2}
                                onChange={() =>
                                    onChangeSelectTag(2, setSelectTagNum)
                                }
                            />
                        </Form.Group>
                    </fieldset>
                    {select_tag_num === 2 && (
                        <Form.Group controlId="tag_color">
                            <Form.Control
                                type="color"
                                defaultValue={select_tag.color}
                            />
                        </Form.Group>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {select_tag_num === 1 && (
                    <Button
                        variant="outline-danger"
                        block
                        onClick={() =>
                            onClickDeleteTag(
                                select_tag,
                                setIsUpdateTagModalShow
                            )
                        }
                    >
                        削除
                    </Button>
                )}
                {select_tag_num === 2 && (
                    <Button
                        variant="outline-danger"
                        block
                        onClick={() =>
                            onClickChangeColor(
                                select_tag.id,
                                setIsUpdateTagModalShow,
                                url_params
                            )
                        }
                    >
                        変更
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
