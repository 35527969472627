import Axios from "axios";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export default async function asyncLogin(params: URLSearchParams) {
    const res = await Axios({
        url: `${host_ins.name}/users/login/`,
        method: "POST",
        data: params,
        headers: {
            Authorization: `Bearer ${extractCookieData(cookieKey_ins.token)}`,
        },
    });
    if (res.status === 200) {
        // クッキーにtoken保存
        document.cookie = `token=${res.data.token}; path=/`;
    } else {
        throw new Error(res.data.error_mes);
    }
}
