import React, { useState } from "react";
import { onClickAddTag } from "../../../functions/onClickAddTag";
import { Modal, Form, Button } from "react-bootstrap";
import { onChangeSelectTag } from "../../../functions/onChangeSelectTag";

export const ModalAdd = (props: any) => {
    const setIsAddTagModalShow = props.setIsAddTagModalShow;
    const is_add_tag_modal_show = props.is_add_tag_modal_show;
    const tags: { id: number; c_code: string; name: string }[] = props.tags;
    const [select_tag_num, setSelectTagNum] = useState(1);

    return (
        <Modal
            show={is_add_tag_modal_show}
            onHide={() => setIsAddTagModalShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>タグの追加</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="tag_form">
                    <fieldset>
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="既存のタグを使用"
                                name="select_add_tag"
                                id="select_exist"
                                checked={select_tag_num === 1}
                                onChange={() =>
                                    onChangeSelectTag(1, setSelectTagNum)
                                }
                            />
                            <Form.Check
                                type="radio"
                                label="新しいタグを使用"
                                name="select_add_tag"
                                id="select_new"
                                checked={select_tag_num === 2}
                                onChange={() =>
                                    onChangeSelectTag(2, setSelectTagNum)
                                }
                            />
                        </Form.Group>
                    </fieldset>
                    {select_tag_num === 1 && (
                        <React.Fragment>
                            <Form.Group controlId="exist_tag">
                                <Form.Label>タグ</Form.Label>
                                <Form.Control as="select">
                                    {tags.map((tag, i) => (
                                        <option
                                            key={i}
                                            data-id={tag.id}
                                            data-color={tag.c_code}
                                        >
                                            {tag.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </React.Fragment>
                    )}
                    {select_tag_num === 2 && (
                        <React.Fragment>
                            <Form.Group controlId="tag_color">
                                <Form.Control type="color" />
                            </Form.Group>
                            <Form.Group controlId="tag_name">
                                <Form.Control
                                    type="text"
                                    placeholder="タグ名"
                                />
                            </Form.Group>
                        </React.Fragment>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-danger"
                    block
                    onClick={() =>
                        onClickAddTag(setIsAddTagModalShow, select_tag_num)
                    }
                >
                    追加
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
