import swal from "sweetalert";
import Axios from "axios";
import { insertTag } from "./insertTag";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const selectExistTag = async (
    setIsAddTagModalShow: React.Dispatch<React.SetStateAction<boolean>>
) => {
    // 現在のactiveの場所を取得
    const main_table_active = document.getElementById(
        "main_table_active"
    ) as HTMLElement;

    const tag_form = document.getElementById("tag_form") as HTMLFormElement;

    // 選択されているoption要素取得
    const selected_option =
        tag_form.exist_tag.options[tag_form.exist_tag.selectedIndex];

    const params = new URLSearchParams();
    params.append(
        "detail_id",
        String(main_table_active.parentElement?.dataset.id)
    );
    params.append("tag_id", selected_option.dataset.id);
    try {
        await Axios({
            url: `${host_ins.name}/detailTags/`,
            method: "POST",
            data: params,
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        insertTag(
            selected_option.dataset.color,
            tag_form.exist_tag.value,
            main_table_active
        );
    } catch (err) {
        swal({
            icon: "error",
            title: err.response.statusText,
            text: err.response.data.err_mes,
        });
    } finally {
        setIsAddTagModalShow(false);
    }
};
