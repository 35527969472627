import React, { useContext } from "react";
import { P0 } from "./p0";
import { Under } from "./under";
import { PagerProps } from "../main";
import { Up } from "./up";

export const PagerSwitch = (): any => {
    const [pager] = useContext(PagerProps);
    if (pager.last_num === 0) {
        return <P0 />;
    }
    if (pager.last_num < 8) {
        return <Under />;
    }
    return <Up />;
};
