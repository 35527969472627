import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Login } from "./components/login";
import { Home } from "./components/home/main";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Route exact path="/login" component={Login} />
                <Route exact path="/" component={Home} />
            </BrowserRouter>
        </div>
    );
}

export default App;
