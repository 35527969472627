import Axios from "axios";
import swal from "sweetalert";
import { addColorEditCell } from "./addColorEditCell";
import Host from "../configs/host";
import hljs from "highlight.js";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export default async function getDisplayData(
    setTableRow: React.Dispatch<
        React.SetStateAction<
            {
                tags: {
                    id: number;
                    c_code: string;
                    name: string;
                }[];
                id: number;
                goal: string;
                code: string;
            }[]
        >
    >,
    setPager: React.Dispatch<
        React.SetStateAction<{
            current_num: number;
            last_num: number;
        }>
    >,
    s: string,
    p: number
) {
    try {
        const res = await Axios({
            url: `${host_ins.name}/mainTables/?s=${s}&p=${p}`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        setTableRow(res.data.for_table);

        setPager(res.data.pager);

        // 編集セルセット
        addColorEditCell();

        // 0.1秒後に色付け
        await new Promise((resolve) => setTimeout(resolve, 100));
        document.querySelectorAll("pre code").forEach((block) => {
            hljs.highlightBlock(block);
        });
    } catch (err) {
        swal({
            icon: "error",
            title: err.response.statusText,
            text: err.response.data.err_mes,
        });
    }
}
