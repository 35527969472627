interface Path {
    login: string;
    home: string;
}

class Path {
    constructor() {
        this.login = "/login";
        this.home = "/";
    }
}

export default Path;
