export const insertTag = (
    color: string,
    name: string,
    main_table_active: HTMLElement
) => {
    const button = document.createElement("button");
    button.style.backgroundColor = color;
    button.innerText = name;
    main_table_active.appendChild(button);
};
