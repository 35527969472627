import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";
import Axios from "axios";
import Host from "../configs/host";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const checkLogin = async (
    setEmail: React.Dispatch<React.SetStateAction<string>>
) => {
    const res = await Axios({
        url: `${host_ins.name}/users/login/check`,
        method: "POST",
        headers: {
            Authorization: `Bearer ${extractCookieData(cookieKey_ins.token)}`,
        },
    });

    // せっと
    setEmail(res.data.email);
};
