import Axios from "axios";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";
import swal from "sweetalert";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export default async function onClickAdd() {
    try {
        await Axios({
            url: `${host_ins.name}/mainTables/`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });

        window.location.href = `/?s=&p=1`;
    } catch (err) {
        swal({
            icon: "error",
            title: err.response.statusText,
            text: err.response.data.err_mes,
        });
    }
}
