import React, { useEffect, useState } from "react";
import {
    NavDropdown,
    Form,
    FormControl,
    Button,
    Row,
    Col,
    InputGroup,
} from "react-bootstrap";
import onClickLogout from "../../functions/onClickLogout";
import { checkLogin } from "../../functions/checkLogin";
import onClickAdd from "../../functions/onClickAdd";
import CookieKey from "../../configs/cookieKey";
import onClickSearch from "../../functions/onClickSearch";
import Title from "../../configs/title";

const cookie_key_ins = new CookieKey();
const title_ins = new Title();

export const Header = () => {
    // state
    const [email, setEmail] = useState("");

    useEffect(() => {
        async function asyncFunc() {
            try {
                // ログイン確認
                await checkLogin(setEmail);
            } catch (err) {
                document.cookie = `${cookie_key_ins.login_err}=; path=/`;
                window.location.href = "/login";
            }
        }

        asyncFunc();
    }, []);

    return (
        <header>
            <Row>
                <Col md="3">
                    <h1>
                        <a href="/">{title_ins.name}</a>
                    </h1>
                </Col>
                <Col md="6">
                    <Form
                        inline
                        onSubmit={(e: any) => {
                            onClickSearch(e);
                        }}
                    >
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search"
                                id="search"
                                spellCheck="false"
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="outline-success"
                                    className="outline-main-btn"
                                    onClick={() => {
                                        onClickAdd();
                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md="3">
                    <NavDropdown title={email} id="header_user_dropdown">
                        <NavDropdown.Item
                            href="/#"
                            onClick={(e: any) => onClickLogout(e)}
                        >
                            ログアウト
                        </NavDropdown.Item>
                    </NavDropdown>
                </Col>
            </Row>
        </header>
    );
};
