import { deleteTag } from "./deleteTag";

export const onClickDeleteTag = (
    select_tag: {
        id: number;
        name: string;
        button: HTMLButtonElement;
    },
    setIsUpdateTagModalShow: React.Dispatch<React.SetStateAction<boolean>>
) => {
    deleteTag(select_tag.id, select_tag.button, setIsUpdateTagModalShow);
};
