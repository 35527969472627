import Path from "../configs/path";
import executeLogin from "../functions/executeLogin";
import swal from "sweetalert";

const path_ins = new Path();

export default function onClickLogin(e: any) {
    async function asyncFunc() {
        try {
            await executeLogin();

            // ホームがめんへ遷移
            window.location.href = path_ins.home;
        } catch (error) {
            swal({
                title: error.response.statusText,
                icon: "error",
                text: error.response.data.err_mes,
            });
        }
    }

    e.preventDefault();
    asyncFunc();
}
