import React, { createContext, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { MainTable } from "./table";
import { Header } from "./header";
import { PagerMain } from "./pager/main";
import Title from "../../configs/title";

const title_ins = new Title();

export const RouterProps = createContext({
    history: {},
    location: { search: "" },
});
export const PagerProps = createContext<
    [
        {
            current_num: number;
            last_num: number;
        },
        React.Dispatch<
            React.SetStateAction<{
                current_num: number;
                last_num: number;
            }>
        >
    ]
>([
    {
        current_num: 0,
        last_num: 0,
    },
    () => {},
]);

export const Home = (props: any) => {
    useEffect(() => {
        document.title = title_ins.name;
    });

    const [pager, setPager] = useState({
        current_num: 0,
        last_num: 0,
    });

    return (
        <Container className="home" fluid={true}>
            <Header />
            <RouterProps.Provider value={props}>
                <PagerProps.Provider value={[pager, setPager]}>
                    <MainTable />
                    <PagerMain />
                </PagerProps.Provider>
            </RouterProps.Provider>
        </Container>
    );
};
