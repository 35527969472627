import Axios from "axios";
import swal from "sweetalert";
import Host from "../configs/host";
import extractCookieData from "./extractCookieData";
import CookieKey from "../configs/cookieKey";

const host_ins = new Host();
const cookieKey_ins = new CookieKey();

export const updateCell = async (main_table_active: HTMLElement) => {
    const params = new URLSearchParams();
    params.append("id", String(main_table_active.parentElement?.dataset.id));
    params.append("column", String(main_table_active.dataset.column));
    params.append("contents", main_table_active.innerText);

    try {
        await Axios({
            url: `${host_ins.name}/mainTables/`,
            method: "PATCH",
            data: params,
            headers: {
                Authorization: `Bearer ${extractCookieData(
                    cookieKey_ins.token
                )}`,
            },
        });
    } catch (err) {
        swal({
            icon: "error",
            title: err.response.statusText,
            text: err.response.data.err_mes,
        });
    }
};
