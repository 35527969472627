interface CookieKey {
    token: string;
    login_err: string;
}

class CookieKey {
    constructor() {
        this.token = "token";
        this.login_err = "login_err";
    }
}

export default CookieKey;
